<template>
  <div class="main-wrapper">
    <!-- :style="`padding-top: ${user.verified || (!Object.keys(user).length && user !== 'ban') ? '97px' : '130px'}`" -->
    <div class="fixed-header">
      <Header v-if="user !== 'ban'" class="black" :theme="'black'"/>
      <PopUpDefault
          class="tgPopup"
          :noScrollLock="true"
          @close="tgPopupState = false"
          v-if="tgPopupState"
        >
          <div class="d-flex">
            <svg
            style="flex-shrink: 0;"
            class="mr-3"
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_12136_9929)">
                <path
                  d="M19 0C13.962 0 9.12594 2.00302 5.56641 5.56492C2.0032 9.12824 0.00098702 13.9608 0 19C0 24.0371 2.00391 28.8732 5.56641 32.4351C9.12594 35.997 13.962 38 19 38C24.038 38 28.8741 35.997 32.4336 32.4351C35.9961 28.8732 38 24.0371 38 19C38 13.9629 35.9961 9.12683 32.4336 5.56492C28.8741 2.00302 24.038 0 19 0Z"
                  fill="url(#paint0_linear_12136_9929)"
                />
                <path
                  d="M8.60046 18.7994C14.1401 16.3864 17.8333 14.7954 19.6798 14.0268C24.9583 11.832 26.0537 11.4508 26.7692 11.4379C26.9266 11.4354 27.2769 11.4743 27.5055 11.6591C27.6955 11.8149 27.7489 12.0257 27.7756 12.1737C27.7994 12.3216 27.832 12.6585 27.8053 12.9216C27.5203 15.9259 26.2823 23.2166 25.653 26.5817C25.3887 28.0055 24.8633 28.4828 24.3556 28.5295C23.2512 28.631 22.4141 27.8003 21.3453 27.1C19.6739 26.0036 18.7298 25.3214 17.1059 24.2518C15.2297 23.0156 16.4469 22.336 17.5156 21.2257C17.7947 20.9351 22.6575 16.5131 22.7495 16.1121C22.7614 16.0619 22.7733 15.8749 22.6605 15.7763C22.5506 15.6774 22.3873 15.7113 22.2686 15.738C22.0994 15.776 19.4305 17.5418 14.253 21.0351C13.4959 21.5559 12.8101 21.8097 12.1926 21.7963C11.5158 21.7818 10.2095 21.4128 9.23874 21.0975C8.05124 20.7107 7.10421 20.5061 7.18733 19.8491C7.2289 19.5071 7.70093 19.1571 8.60046 18.7994Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_12136_9929"
                  x1="1900"
                  y1="0"
                  x2="1900"
                  y2="3800"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#2AABEE" />
                  <stop offset="1" stop-color="#229ED9" />
                </linearGradient>
                <clipPath id="clip0_12136_9929">
                  <rect width="38" height="38" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div class="modal-popup__text">
            Больше информации в удобном формате в нашем новом Телеграм-канале
            для педиатров
          </div>
          </div>
          
          <div
            class="
              modal-popup__btn-accept
              d-md-inline-flex
              button button_pink
              ml-auto
            "
            @click="goToTg()"
          >
           

            Присоединиться
          </div>
        </PopUpDefault>
    </div>

    <router-view v-if="user !== 'ban'" />
    <div v-else>
      <Error
        class="allPage"
        :title="'Доступ заблокирован'"
        :description="'Вы несколько раз ввели неверный пароль. Доступ к порталу временно заблокирован. Пожалуйста, повторите попытку через 15 минут'"
      />
    </div>
    <div class="bottom-tip" v-if="user !== 'ban'">
      <div class="content" v-if="pageVerify && pageVerify.length">
        {{
          `Номер одобрения: ${pageVerify[0].number} Дата одобрения: ${this.$m(
            pageVerify[0].approval_date
          ).format("DD.MM.YYYY")}. Дата истечения: ${this.$m(
            pageVerify[0].expire_date
          ).format("DD.MM.YYYY")}`
        }}
      </div>
    </div>
    <Footer v-if="user !== 'ban'" />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import Error from "@/views/Error.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Header, Footer, Error },
  name: "main-layout-black",
  data: () => ({
    tgSpeciality: ["пульмонология", "неонатология", "педиатрия"],
    tgPopupState: false,
    lastKnownScrollY: 0,
    currentScrollY: 0,
    ticking: false,
    eleHeader: null,
    headerContainer: null,
    classes: {
      pinned: "header-pin",
      unpinned: "header-unpin",
    },
  }),
  computed: {
    ...mapGetters(["user", "pageVerify", "Nosologys"]),
  },
  methods: {
    ...mapActions([
      "fetchPageVerify",
      "fetchNosologys",
    ]),
     goToTg() {
      if (this.user.invite_link) {
        window.open(this.user.invite_link, "_blank");
        this.tgPopupState = false;
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "tg-popup", {
            "tg-popup": {
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
    onScroll() {
      this.currentScrollY = window.pageYOffset;
      this.update();
    },
    update() {
      this.headerContainer = document.querySelector(".fixed-header");
      if(!this.headerContainer) return
      this.eleHeader = this.headerContainer.querySelector(".header");
      if (this.currentScrollY < this.lastKnownScrollY) {
        this.pin();
      } else if (
        this.currentScrollY > this.lastKnownScrollY &&
        this.currentScrollY > this.eleHeader.offsetHeight
      ) {
        this.unpin();
      }
      this.lastKnownScrollY = this.currentScrollY;
    },
    pin() {
      if (this.eleHeader.classList.contains(this.classes.unpinned)) {
        this.eleHeader.classList.remove(this.classes.unpinned);
        this.eleHeader.classList.add(this.classes.pinned);
        this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
      }
    },
    unpin() {
      if (
        this.eleHeader.classList.contains(this.classes.pinned) ||
        !this.eleHeader.classList.contains(this.classes.unpinned)
      ) {
        this.eleHeader.classList.remove(this.classes.pinned);
        this.eleHeader.classList.add(this.classes.unpinned);
      }
    },
  },
  async mounted() {
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }

    this.headerContainer = document.querySelector(".fixed-header");
    this.eleHeader = this.headerContainer.querySelector(".header");
    this.headerContainer.style.height = this.eleHeader.offsetHeight + "px";
    document.addEventListener("scroll", this.onScroll, false);
     if (
      localStorage.getItem("token") &&
      Object.keys(this.user).length &&
      this.user.speciality &&
      !this.user.tg_id &&
      this.tgSpeciality.includes(this.user.speciality.toLowerCase()) &&
      this.user.invite_link
    ) {
      setTimeout(() => {
        this.tgPopupState = true;
      }, 20000);
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll, false);
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
        this.tgPopupState = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-header {
  position: relative;
  @media screen and (min-width: 1221px) {
    height: 97px;
  }
  & .header {
    @media screen and (min-width: 1221px) {
      position: static;
      z-index: 10000;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: #fff;
      // transform: translateY(0);
      transition: 0.3s;

      &-unpin {
        // transform: translateY(-100%);
        // transform: translateY(0);
      }

      &-pin {
        // transform: translateY(0);
      }
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  @media screen and (min-width: 1221px) {
    // padding-top: 130px;
  }
  @media screen and (max-width: 1220px) {
    padding-top: 0px !important;
  }
}

.bottom-tip {
  margin-top: 80px;
  margin-bottom: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #b2b4b4;

  @media screen and (max-width: 1220px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>